@charset "UTF-8";

/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/

@font-face {
  font-family: "PPMori";
  src: url("./fonts/PPMori-Regular.woff2") format("woff2"),
    url("./fonts/PPMori-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PPMori";
  src: url("./fonts/PPMori-Extralight.woff2") format("woff2"),
    url("./fonts/PPMori-Extralight.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "PPMori";
  src: url("./fonts/PPMori-Bold.woff2") format("woff2"),
    url("./fonts/PPMori-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: "PPMori", sans-serif;
}

/*--------------------------------------------------------------
# Config
--------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
main {
  display: block;
}
audio:not([controls]) {
  display: none;
}
[hidden] {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 1em;
  line-height: 1.35;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
::-moz-selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}
a {
  text-decoration: none;
}
a:focus,
a:hover,
a:active {
  outline: none;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
blockquote {
  margin: 0;
}
dfn {
  font-style: italic;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre;
  word-wrap: break-word;
}
q {
  quotes: none;
}
q::before,
q::after {
  content: "";
}
small {
  font-size: 85%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
nav ul,
nav ol,
ul,
li {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}
img {
  border: 0;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
form {
  margin: 0;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
label {
  cursor: pointer;
}
legend {
  border: 0;
  padding: 0;
  white-space: normal;
}
button,
input,
select,
textarea {
  font-family: var(--font);
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}
button,
input {
  line-height: normal;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
textarea {
  box-shadow: none;
  appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
input:invalid,
textarea:invalid {
  background-color: #f0dddd;
}
input,
textarea,
select {
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: transparent;
}
td {
  vertical-align: top;
}

/*--------------------------------------------------------------
# Helpers
--------------------------------------------------------------*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

.centered {
  text-align: center;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.has-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

:root {
  --white: #fff;
  --black: #3c3c3b;
  --black-v2: #000;
  --orange: #f05a28;
  --orange-light: #f08024;
  --orange-v2: #ff590b;
  --orange-v3: #ff9211;
  --red: #e64845;
  --green: #005344;
  --green-middle: #318c8c;
  --green-light: #7cc9c2;
  --grey-light: #e5e5e5;
  --grey-lighter: #f2f2f2;
  --green-v2: #005344;
  --green-v3: #cde966;
  --font: "PPMori", sans-serif;
  --transition: 0.4s ease;
}

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/

body {
  background-color: var(--grey-lighter);
  color: var(--black);
  font-family: var(--font);
  font-size: 18px;
  font-weight: 400;
}

.scroll-disabled {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.wrap {
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 20px;
  width: 100%;
}

.main {
  width: 100%;
}

/*--------------------------------------------------------------
# Links and buttons
--------------------------------------------------------------*/
a,
button {
  appearance: none;
  outline: none;
}

button,
input[type="button"],
input[type="submit"] {
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.btn {
  border: 1px solid;
  border-radius: 100px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 48px;
  padding: 0 24px;
  text-transform: uppercase;
  transition: var(--transition);
  cursor: pointer;
}

.btn--small {
  height: 30px;
  line-height: 30px;
  padding: 0 13px;
}

.btn--orange {
  background-color: var(--orange);
  border-color: var(--orange);
  color: var(--white);
  cursor: pointer;
}

.btn--orange:hover {
  background-color: rgba(255, 255, 255, 0);
  color: var(--orange);
}

.btn--black {
  background-color: var(--black-v2);
  border-color: var(--black-v2);
  color: var(--white);
}

.btn--black:hover {
  background-color: rgba(255, 255, 255, 0);
  color: var(--black-v2);
}

.btn--green {
  background-color: var(--green);
  border-color: var(--green);
  color: var(--white);
}

.btn--green:hover {
  background-color: rgba(255, 255, 255, 0);
  color: var(--green);
}

.btn--red {
  background-color: var(--red);
  border-color: var(--red);
  color: var(--white);
}

.btn--red:hover {
  background-color: rgba(255, 255, 255, 0);
  color: var(--red);
}

.btn--white {
  background-color: transparent;
  border-color: var(--white);
  color: var(--white);
}

.btn--white:hover {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--black-v2);
}

.btn-back {
  color: var(--black);
  font-size: 12px;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
}

.btn-back::before {
  background-image: url(./images/ico-arrow-left-black.svg);
  background-size: 6px 9px;
  content: "";
  display: inline-block;
  height: 9px;
  margin: 0 9px 0 3px;
  width: 6px;
}

.btn-back:hover {
  text-decoration: underline;
}

.cmp-title {
  width: 100%;
  display: block;
  color: var(--black);
  font-size: 24px;
  font-style: normal;
  font-weight: 100;
  line-height: 110%;
  margin-bottom: 16px;
}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
input,
textarea {
  background: var(--white);
  border: 2px solid var(--grey-light);
  border-radius: 0;
  box-shadow: none;
  color: var(--black);
  font-family: var(--font);
  font-size: 16px;
  padding: 0 20px;
  width: 100%;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-moz-placeholder,
textarea::-moz-placeholder,
input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input::-ms-input-placeholder,
textarea::-ms-input-placeholder,
input::placeholder,
textarea::placeholder {
  color: rgba(60, 60, 59, 0.5);
}

.form {
  text-align: left;
  width: 100%;
}

.form__row {
  display: flex;
  margin-bottom: 40px;
  width: 100%;
}

.form__row--fullwidth .form__item {
  width: 100%;
}

.form__item {
  margin-right: 1.55%;
  width: 32.3%;
}

.form__item:last-child,
.form__item:nth-child(3n) {
  margin-right: 0;
}

.form__item--required .form__label::after {
  color: var(--orange);
  content: "*";
  padding-left: 7px;
}

.form__label {
  display: block;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 4px;
}

.form__item-error {
  color: var(--red);
  font-size: 12px;
  font-weight: 500;
}

.form__input {
  height: 50px;
  border-radius: 24px;
  padding: 0 24px;
  border: 1px solid var(--black-v2);
}

.form__input[disabled] {
  background-color: var(--grey-light);
}

.form__dropdown {
  position: relative;
  width: 100%;
}

.form__dropdown--active .form__dropdown-btn--active::after {
  transform: translateY(-50%) rotate(180deg);
}

.form__dropdown--active .form__dropdown-list {
  display: block;
}

.form__dropdown--error .form__dropdown-btn::placeholder {
  color: var(--red);
}

.form__dropdown-btn {
  background-color: var(--white);
  border: 2px solid var(--grey-light);
  color: var(--black);
  font-family: var(--font);
  font-size: 16px;
  overflow: hidden;
  padding: 13px 40px 14px 13px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.form__dropdown-btn--placeholder {
  color: rgba(60, 60, 59, 0.8);
}

.form__dropdown-btn::after {
  background-image: url(./images/ico-arrow-down.svg);
  background-size: 16px 11px;
  content: "";
  height: 11px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform var(--transition);
  width: 16px;
}

.form__dropdown-list {
  background-color: var(--white);
  border: 2px solid var(--grey-light);
  border-top: 0;
  display: none;
  left: 0;
  max-height: 240px;
  overflow: auto;
  padding: 1px 18px 3px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 5;
}

.form__dropdown-item {
  border-bottom: 1px solid var(--grey-light);
  cursor: pointer;
  font-size: 16px;
  padding: 14px 0 12px;
  transition: color var(--transition);
  width: 100%;
}

.form__dropdown-item:last-child {
  border-bottom: 0;
}

.form__dropdown-item:hover,
.form__dropdown-item--selected {
  color: var(--orange);
}

.form__dropdown-item-multi {
  padding-left: 26px;
  position: relative;
}

.form__dropdown-item-multi::before {
  top: 16px;
}

.form__dropdown-item-multi.form__dropdown-item--selected::after {
  left: 2px;
  top: 19px;
}

.form__dropdown-item-multi::before,
.form__checkbox-label::before {
  border: 1px solid var(--black);
  border-radius: 2px;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  width: 16px;
}

.form__dropdown-item-multi.form__dropdown-item--selected::after,
.form__checkbox-input:checked ~ .form__checkbox-label::after {
  background-image: url(./images/ico-checkmark.svg);
  background-size: 12px 10px;
  content: "";
  height: 10px;
  position: absolute;
  width: 12px;
}

.form__checkbox {
  position: relative;
  width: 100%;
}

.form__checkbox:first-of-type {
  padding-top: 6px;
}

.form__checkbox-input {
  border: 0;
  height: 1px;
  left: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.form__checkbox-input:checked ~ .form__checkbox-label::after {
  left: 2px;
  top: 9px;
}

.form__checkbox-label {
  display: inline-block;
  font-size: 16px;
  padding: 4px 0 3px 36px;
  position: relative;
}

.form__checkbox-label::before {
  top: 6px;
}

.form__radio {
  position: relative;
  width: 100%;
}

.form__radio:first-of-type {
  padding-top: 6px;
}

.form__radio-input {
  border: 0;
  height: 1px;
  left: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.form__radio-input:checked ~ .form__radio-label::after {
  background-color: var(--orange);
  border-radius: 50%;
  content: "";
  height: 10px;
  left: 3px;
  position: absolute;
  top: 9px;
  width: 10px;
}

.form__radio-label {
  display: inline-block;
  font-size: 16px;
  padding: 4px 0 3px 36px;
  position: relative;
}

.form__radio-label::before {
  border: 1px solid var(--black);
  border-radius: 50%;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 16px;
}

.form__textarea {
  height: 140px;
  padding: 14px 20px;
}

.form__upload {
  border: 0;
  color: var(--black);
  font-size: 14px;
  padding: 0;
  position: relative;
}
.form__upload::-webkit-file-upload-button {
  background: var(--white);
  border: 1px solid var(--black);
  color: var(--black);
  cursor: pointer;
  font-family: var(--font);
  font-size: 12px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  margin-right: 10px;
  padding: 0 23px 0 42px;
  text-transform: uppercase;
}
.form__upload::before {
  background-image: url(./images/ico-attach.svg);
  background-size: 7px 13px;
  content: "";
  height: 13px;
  left: 28px;
  position: absolute;
  top: 19px;
  width: 7px;
}

.form__btn {
  margin-right: 15px;
}

.form-error,
.form-error .form__dropdown-btn {
  border-color: var(--red) !important;
  color: var(--red) !important;
}

.form-error.form__checkbox-input ~ .form__checkbox-label,
.form-error.form__radio-input ~ .form__radio-label {
  color: var(--red);
}

.form-error.form__radio-input ~ .form__radio-label::before {
  border-color: var(--red);
}

.form-error::placeholder {
  color: var(--red);
}

.form__reorder {
  border-top: 2px solid var(--grey-light);
  width: 100%;
}

.form__reorder-listitem {
  border-bottom: 2px solid var(--grey-light);
  width: 100%;
}

.form__reorder-item {
  cursor: move;
  font-size: 16px;
  padding: 9px 55px 6px 20px;
  position: relative;
  width: 100%;
}

.form__reorder-item::after {
  background-image: url(./images/ico-reorder.svg);
  background-size: 12px 16px;
  content: "";
  height: 16px;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
}

@media only screen and (max-width: 767px) {
  .form__row {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .form__item {
    width: 100%;
  }
  .form__label {
    display: block;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 4px;
  }
}

/*--------------------------------------------------------------
# Login
--------------------------------------------------------------*/

.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--green-v2);
  padding: 0 20px;
}

.login__box {
  background-color: var(--white);
  max-width: 570px;
  padding: 32px;
  text-align: center;
  width: 100%;
  border-radius: 24px;
}

.login__box .form__actions {
  text-align: center;
}

.login__header {
  width: 100%;
}

.login__header-inner {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
  text-align: center;
  width: 100%;
}

.login__logo {
  width: 200px;
}

.login__heading {
  font-size: 34px;
  font-weight: 900;
  color: var(--white);
  position: relative;
  padding-left: 20px;
}

.login__subheading {
  font-size: 30px;
  font-style: normal;
  font-weight: 100;
  line-height: 110%;
  margin-bottom: 24px;
}

.login__form {
  padding: 0 25px;
}

.login__form .form__row {
  margin-bottom: 16px;
}

.login__box .form__item .form__label {
  font-weight: 900;
  font-size: 16px;
}

@media only screen and (min-width: 768px) {
  .login__header {
    margin-bottom: 30px;
  }

  .login__heading {
    padding-left: 20px;
  }

  .login__heading::before {
    content: "";
    width: 2px;
    height: 100%;
    background-color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
  }

  .login__box .form__item {
    position: relative;
  }

  .login__box .form__actions {
    text-align: center;
  }

  .login__box .form__item .form__label {
    position: absolute;
    top: 14px;
    left: 24px;
  }

  .login__box .form__item input {
    padding-left: 115px;
  }
}

@media only screen and (max-width: 767px) {
  .login__box {
    padding: 24px;
  }
  .login__header-inner {
    flex-direction: column;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.header {
  align-items: stretch;
  background-color: var(--white);
  display: flex;
  height: 80px;
  justify-content: space-between;
  transition: var(--transition);
  transition-property: height, padding;
  width: 100%;
  align-items: center;
}

.header--orange .header__buttons {
  font-size: 0;
}

.header__logo {
  align-items: center;
  display: flex;
  font-family: var(--font);
  margin-bottom: -2px;
  padding: 10px 50px;
  transition: padding var(--transition);
}

.header__logo-img {
  width: 120px;
}

.header__logo-text {
  color: var(--black-v2);
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  transition: font-size var(--transition);
}

.header__logo-text:not(:first-child) {
  padding-left: 11px;
  position: relative;
}

.header__logo-text:not(:first-child)::before {
  background-color: var(--black-v2);
  content: "";
  left: 0;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}

.header__actions {
  align-items: center;
  display: flex;
  padding: 10px 50px;
}

.header__buttons:not(:first-child) {
  margin-left: 20px;
  padding-left: 20px;
  position: relative;
}

.header__buttons:not(:first-child)::before {
  background: var(--black);
  content: "";
  height: 40px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}

.header__btn {
  margin-left: 20px;
}

.header__btn:first-of-type {
  margin-left: 0;
}

.header__nav-link {
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
  margin-left: 27px;
  transition: color var(--transition);
  position: relative;
  &::before {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--orange-v2);
    transition: width var(--transition);
  }
}

.header__nav-link:first-child {
  margin-left: 0;
}

.header__nav-link--active {
  color: var(--orange);
  &::before {
    content: none;
  }
}

.header__nav-link:hover {
  color: var(--orange-v2);
  &::before {
    width: 100%;
  }
}

.header__hamburger {
  display: none;
  height: 22px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: auto 20px;
  position: relative;
  width: 30px;
}

.header__hamburger::before,
.header__hamburger::after {
  background-color: var(--black);
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: transform var(--transition);
  width: 100%;
}

.header__hamburger::before {
  top: 0;
}

.header__hamburger::after {
  bottom: 0;
}

.header__hamburger__bar {
  background-color: var(--black);
  display: block;
  height: 2px;
  padding: 0 5px;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .header__logout--mobile {
    display: none;
  }
  .header {
    padding-right: 50px;
  }
  .header__logout {
    position: relative;
    margin-left: 20px;
    padding-left: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .header__logout--desktop {
    display: none;
  }
  .header {
    padding-right: 50px;
  }
  .header__hamburger {
    display: block;
  }
  .header__hamburger--open .header__hamburger__bar {
    display: none;
  }
  .header__hamburger--open::before {
    transform: rotate(-45deg) translate(-7px, 7px);
  }
  .header__hamburger--open::after {
    transform: rotate(45deg) translate(-7px, -7px);
  }
  .header__actions {
    background-color: var(--white);
    border-bottom: 2px solid var(--grey-light);
    display: none;
    left: 0;
    padding: 20px;
    position: fixed;
    top: 80px;
    width: 100%;
    z-index: 10;
  }
  .header__actions--open {
    display: block;
  }
  .header__buttons {
    align-items: center;
    display: flex;
  }
  .header__btn {
    margin: 0 0 10px;
  }
  .header__logout--mobile {
    margin-left: auto;
  }
}

@media only screen and (max-width: 992px) {
  .header {
    padding-right: 0;
  }
  .header__logo {
    padding: 10px 20px;
  }
  .header__buttons:not(:first-child) {
    margin-left: 0;
    padding-left: 0;
    width: 100%;
  }
  .header__buttons:not(:first-child)::before {
    display: none;
  }
  .header__nav {
    margin-bottom: 10px;
  }
  .header__nav-link {
    display: block;
    margin: 0;
    padding: 10px 0;
    width: 100%;
  }
  .header__buttons {
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }
  .header__logout--mobile {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    height: 60px;
  }
  .header__logo {
    width: 100%;
  }
  .header__logo-img {
    width: 70px;
  }
  .header__logo-text {
    font-size: 12px;
  }
  .header__actions {
    top: 60px;
  }
}

/*--------------------------------------------------------------
# Loader
--------------------------------------------------------------*/

.loader {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 15;
}

.loader__dot {
  animation: loadingDots 0.6s infinite linear alternate;
  background-color: var(--orange);
  border-radius: 50%;
  height: 12px;
  margin: 0 7px;
  position: relative;
  transform: scale(1);
  width: 12px;
}

.loader__dot:first-child {
  animation-delay: 0s;
}

.loader__dot:nth-child(2) {
  animation-delay: 0.3s;
}

.loader__dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loadingDots {
  0%,
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

/*--------------------------------------------------------------
# Popup
--------------------------------------------------------------*/

.popup {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
}

.popup__background {
  background-color: rgba(0, 0, 0, 0.35);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.popup__content {
  background-color: var(--white);
  left: 50%;
  max-height: calc(100vh - 40px);
  max-width: 720px;
  overflow: auto;
  padding: 60px;
  position: absolute;
  -ms-overflow-style: none;
  scrollbar-width: none;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;
  border-radius: 24px;
}

.popup__content::-webkit-scrollbar {
  display: none;
}

.popup__content--small {
  max-width: 400px;
  padding: 64px 30px 60px;
}

.popup__content--medium {
  max-width: 720px;
  padding: 40px 30px;
}

.popup__content--large {
  max-width: 900px;
  padding: 64px 30px 60px;
  max-height: calc(100vh - 40px);
}

.popup__close {
  background-image: url(./images/ico-close.svg);
  background-size: 18px 18px;
  height: 18px;
  position: absolute;
  right: 16px;
  top: 18px;
  width: 18px;
}

.popup__heading-plain {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 30px;
  text-align: center;
}

.popup__actions {
  text-align: center;
  width: 100%;
}

.popup__btn {
  margin: 0 8px;
}

.popup__wrapper {
  width: 100%;
}
.popup__items {
  max-height: 500px;
  overflow-y: auto;
}
.popup__title {
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 25px;
}

.popup__item {
  display: flex;
  padding: 10px;
  width: 100%;
}

.popup__item:first-child {
  background-color: var(--orange-v3);
  color: var(--white);
  border-radius: 8px 8px 0 0;
}

.popup__item-cell {
  font-size: 14px;
  width: 100%;
}

.popup__item-cell--head {
  font-weight: 600;
}

.popup .form__item {
  margin-bottom: 30px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .popup__content {
    max-width: calc(100% - 40px);
  }
  .popup__heading-plain {
    margin-bottom: 10px;
  }
  .popup .form__item {
    margin-bottom: 10px;
  }
  .popup__item {
    padding: 7px 0;
  }
  .popup__item-cell {
    font-size: 13px;
    padding-right: 5px;
  }
  .popup .form__item input {
    padding: 0 10px;
  }
}

/*--------------------------------------------------------------
# Control panel head
--------------------------------------------------------------*/
.panel-head {
  background-color: var(--white);
  padding: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 16px;
}

.panel-head__list {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.panel-head__item {
  align-items: center;
  display: flex;
  min-height: 46px;
  position: relative;
  width: 100%;
}

.panel-head__item:not(:last-child) {
  margin-right: 16px;
}

.panel-head__item .form__item {
  margin: 0;
}

.panel-head__search {
  position: relative;
  width: 100%;
}

.panel-head__search .MuiAutocomplete-hasClearIcon label {
  display: none;
}

.panel-head__search .css-1mynrwe .MuiOutlinedInput-root {
  padding: 8px;
}

.panel-head__search fieldset {
  display: none;
}

.panel-head__search .MuiFormLabel-colorPrimary {
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.panel-head__search .css-u9osun.Mui-focused {
  display: none;
}

.panel-head__search .Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}

.panel-head__search .css-1mynrwe {
  width: 100%;
}

.panel-head__input {
  height: 50px;
  padding: 2px 40px 0 18px;
  width: 260px;
}

.panel-head__btn {
  height: 46px;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 46px;
}

.panel-head__btn-image {
  height: 18px;
  width: 18px;
}

.panel-head__title {
  font-size: 20px;
  font-weight: 400;
}

.panel-head--page .form__dropdown {
  min-width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel-head__submit {
  height: 46px;
  margin-top: 2px;
}

.panel-head__zooming {
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel-head__zooming-item {
  margin-left: 15px;
}

.panel-head__zoom {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  border: 2px solid var(--black);
  height: 40px;
  width: 40px;
}

.panel-head__zoom--in {
  background-image: url(./images/ico-zoom-in.svg);
}

.panel-head__zoom--out {
  background-image: url(./images/ico-zoom-out.svg);
}

.panel-head__zoom--reset {
  background-image: url(./images/ico-zoom-reset.svg);
}

.panel-head__search .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-family: var(--font);
  font-size: 16px;
  font-weight: 500;
  color: var(--black-v2);
}
.panel-head__search
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  display: none;
}
.panel-head__search .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0;
}
.panel-head__search .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--black-v2);
  border-radius: 24px;
}
.panel-head__search
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--orange-v2);
}
.panel-head__search
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--orange-v2);
}

.panel-head__search .css-i4bv87-MuiSvgIcon-root {
  color: var(--black-v2);
}

.panel-head__item .css-rdk77t-MuiAutocomplete-root {
  width: 100%;
}

.panel-head__item--back {
  width: fit-content;
}

.panel-head .btn-back {
  border-radius: 100px;
  background-color: var(--black-v2);
  color: var(--white);
  padding: 0 36px;
  line-height: 48px;
  text-decoration: none;
  transition: var(--transition);
  border: 1px solid var(--black-v2);
}

.panel-head .btn-back::before {
  content: none;
}

.panel-head .btn-back:hover {
  background-color: var(--white);
  color: var(--black-v2);
}

.panel-head__list .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  height: 48px;
  border-radius: 24px;
  line-height: 48px;
  border: none;
}

.panel-head__list--entrence {
  flex-wrap: wrap;
  justify-content: space-between;
}

.panel-head__list--entrence .form__input {
  padding-left: 75px;
}

.panel-head__list--entrence .form__item {
  position: relative;
  margin-right: 0;
  width: 34%;
}

.panel-head__list--entrence .form__label,
.panel-head__list--entrence .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--black-v2);
  font-family: var(--font);
  position: relative;
  top: auto;
  left: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  transform: none;
}

.panel-head__list--entrence .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  margin-right: auto;
}

.panel-head__list--entrence .form__label {
  position: absolute;
  left: 24px;
  top: 14px;
}

.panel-head__list--entrence .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 25%;
  border-radius: 24px;
  border: 1px solid var(--black-v2);
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 0 24px;
}

.panel-head--page .MuiOutlinedInput-root {
  border: 1px solid var(--black-v2);
  border-radius: 100px;
}

.panel-head__list--entrence
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0;
  text-align: right;
}

.panel-head__list--entrence
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::-webkit-calendar-picker-indicator {
  background-image: url(./images/ico-calendar.svg);
  width: 20px;
  height: 20px;
  margin-left: auto;
}

.panel-head__list--entrence .MuiOutlinedInput-root {
  border: none;
}

.panel-head__list--entrence .css-1x5jdmq {
  padding: 10px;
}

.panel-head__list--entrence .css-1x5jdmq::-webkit-calendar-picker-indicator {
  background-image: url(./images/ico-calendar.svg);
  width: 20px;
  height: 20px;
  margin-left: auto;
}

.MuiOutlinedInput-notchedOutline .css-igs3ac {
  border: none;
}
.panel-head__list--entrence .css-7nxzgd {
  display: none;
}
.panel-head__list--entrence .css-i44wyl {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--black);
  border-radius: 100px;
  padding: 0 24px;
  width: 22%;
}
.panel-head__list--entrence .css-u9osun {
  position: relative;
  top: auto;
  left: auto;
  color: var(--black-v2);
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  transform: none;
}
.panel-head__list--entrence .css-igs3ac {
  border: none;
}

.panel-head__list--entrence .panel-head__item {
  margin-right: 0;
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .panel-head__list--entrence {
    gap: 16px;
  }
  .panel-head__list--entrence .form__item {
    width: 100%;
  }
  .panel-head__list--entrence
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
  .panel-head__list--entrence .css-i44wyl {
    width: 44%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .panel-head__item--back {
    width: auto;
  }
  .panel-head__search,
  .panel-head__item .css-rdk77t-MuiAutocomplete-root {
    width: 100%;
  }
  .panel-head__search .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 14px;
  }
  .panel-head__list--entrence .css-i44wyl {
    width: 48%;
  }
}

@media only screen and (max-width: 767px) {
  .panel-head__list--entrence .css-i44wyl {
    width: 100%;
  }
  .panel-head__list--entrence .form__item {
    width: 100%;
  }
  .panel-head {
    padding: 16px;
  }
  .panel-head__list {
    flex-wrap: wrap;
    gap: 16px;
  }
  .panel-head__item {
    min-height: 1px;
    padding-right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .panel-head__item--back {
    justify-content: flex-start;
  }
  .panel-head__item--back::after {
    display: none;
  }
  .panel-head__item:not(:last-child) {
    margin-right: 0;
  }
  .panel-head__item:last-child {
    margin-bottom: 0;
  }
  .panel-head__search {
    width: 100%;
  }
  .panel-head__input {
    height: 40px;
    width: 100%;
  }
  .panel-head__btn {
    height: 36px;
  }
  .panel-head__title {
    border-top: 2px solid var(--grey-light);
    font-size: 18px;
    margin-top: 4px;
    padding-top: 6px;
    width: 100%;
  }
  .panel-head__item .css-rdk77t-MuiAutocomplete-root {
    width: 100%;
  }
  .panel-head .btn-back {
    margin: 0;
  }
  .panel-head__list--entrence
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }
}

/*--------------------------------------------------------------
# Home page
--------------------------------------------------------------*/
.homepage {
  align-items: stretch;
  background-color: var(--grey-lighter);
  display: flex;
  width: 100%;
}

.homepage__btn {
  color: var(--orange);
  font-size: 36px;
  font-weight: bold;
  height: auto;
  padding: 20px;
  transition: var(--transition);
  transition-property: color, background;
  width: 50%;
}

.homepage__btn:first-child {
  border-right: 2px solid var(--grey-light);
}

.homepage__btn:hover,
.homepage__btn:hover .homepage__btn-description {
  color: var(--white);
}

.homepage__btn--green:hover {
  background-color: var(--green);
}

.homepage__btn--orange:hover {
  background-color: var(--orange);
}

.homepage__btn-description {
  color: var(--black);
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding-top: 8px;
  transition: color var(--transition);
}

@media only screen and (max-width: 767px) {
  .homepage {
    height: calc(100vh - 60px);
    flex-direction: column;
  }
  .homepage__btn {
    font-size: 28px;
    height: 50%;
    width: 100%;
  }
  .homepage__btn:first-child {
    border-bottom: 2px solid var(--grey-light);
    border-right: 0;
  }
  .homepage__btn-description {
    font-size: 16px;
  }
}

/*--------------------------------------------------------------
# gate
--------------------------------------------------------------*/
.gate {
  margin-top: 20px;
  width: 100%;
}

.gate__box {
  border-radius: 16px;
  background-color: var(--white);
  margin-bottom: 20px;
  padding: 24px;
  width: 100%;
}

.gate__box--split-view {
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;
  gap: 20px;
  padding: 0;
  margin-top: 20px;
}

.gate__user-info {
  background-color: var(--white);
  padding: 20px;
  border-radius: 16px;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.gate__box-name {
  font-family: var(--font);
  display: block;
  font-size: 24px;
  font-weight: 100;
}

.gate__box-number {
  font-family: var(--font);
  display: block;
  font-size: 16px;
}

.gate__box-date {
  display: block;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font);
}

.gate__box-date strong,
.gate__box-total-time strong,
.gate__box-number strong {
  margin-right: 5px;
}

.gate__box-total-time {
  color: var(--black);
  display: block;
  font-size: 16px;
  width: 100%;
}

.gate__table {
  background-color: var(--white);
  padding: 20px;
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
}

.gate__table tr:nth-child(even) {
  background-color: var(--grey-lighter);
}

.gate__table-head {
  background-color: var(--orange-v3);
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
  padding: 8px 16px;
  text-align: left;
}

.gate__table-item {
  font-size: 14px;
  padding: 10px 16px;
  vertical-align: middle;
}

.gate__table-button {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  transition: color var(--transition);
}

.gate__table-button:hover {
  color: var(--orange);
}

.gate__table-button:hover::after {
  background-image: url(./images/ico-arrow-right-orange.svg);
  transform: rotate(0);
}

.gate__table-button::after {
  background-image: url(./images/ico-arrow-left-black.svg);
  background-size: 6px 9px;
  content: "";
  display: inline-block;
  height: 9px;
  margin-left: 5px;
  transform: rotate(180deg);
  width: 6px;
}

.gate__week {
  width: 100%;
  padding: 20px;
  background-color: var(--white);
  border-radius: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.gate__week-item {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  transition: var(--transition);
}

.gate__week-button-icon {
  transition: var(--transition);
}

.gate__week-item--current .gate__week-content {
  display: block;
}

.gate__week-item--current .gate__week-button-icon {
  transform: scaleY(-1);
}

.gate__week-button {
  align-items: center;
  background-color: var(--green-v3);
  color: var(--black);
  display: flex;
  font-size: 15px;
  font-weight: 400;
  justify-content: space-between;
  padding: 0 16px;
  line-height: 48px;
  text-align: left;
  transition: var(--transition);
  transition-property: background, color;
  width: 100%;
  font-family: var(--font);
}

.gate__week-button:hover,
.gate__week-item--current .gate__week-button {
  background-color: var(--green-v3);
  border-radius: 8px;
}

.gate__week-date {
  font-size: 14px;
  font-weight: 400;
}

.gate__week-content {
  display: none;
}

.gate__week-total-time {
  color: var(--black);
  display: block;
  font-size: 15px;
  padding: 6px 10px 5px 10px;
  width: 100%;
}

.gate-user__date-details {
  padding-left: 10px;
  width: 100%;
}

.gate__week-row {
  font-size: 13px;
  display: flex;
  padding: 5px 10px;
  width: 100%;
}

.gate__week-row:nth-child(even) {
  background-color: var(--grey-lighter);
}

.gate__week-row-item {
  width: calc(100% / 3);
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .gate__table-item {
    padding: 8px 10px;
  }
  .gate__table-button,
  .gate__box-date {
    font-size: 13px;
  }
  .gate__box-name {
    font-size: 23px;
  }
  .gate__box-number,
  .gate__box-total-time,
  .gate__week-button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .gate {
    padding: 20px 0;
  }

  .gate__table-item {
    font-size: 12px;
    padding: 5px 10px;
  }
  .gate__table-button {
    font-size: 12px;
  }
  .gate__user-info {
    padding-right: 0;
    width: 100%;
  }
  .gate__box-name {
    font-size: 20px;
  }
  .gate__box-number,
  .gate__week-button {
    font-size: 14px;
  }
  .gate__box-date,
  .gate__box-total-time,
  .gate__week-total-time {
    font-size: 13px;
  }
  .gate__week-row {
    font-size: 12px;
  }
}

/*--------------------------------------------------------------
# map
--------------------------------------------------------------*/
.map {
  background-color: var(--grey-lighter);
  width: 100%;
}

.map__inner {
  display: flex;
  height: calc(100vh - 169px);
  overflow: hidden;
  padding: 20px 0;
  width: 100%;
}

.map__holder {
  border: 2px solid var(--grey-light);
  width: 100%;
}

.map__marker {
  background-image: url("./components/icons/pin_vegait.svg");
  background-size: cover;
  cursor: pointer;
  height: 40px;
  width: 25px;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  padding: 30px 20px 16px;
  text-align: center;
}

.map__popup-title {
  color: var(--orange-light);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.map__popup-address {
  font-size: 14px;
  margin-bottom: 10px;
}

.map__popup-btn {
  padding: 0 20px;
  width: 100%;
}

.mapboxgl-popup-close-button {
  color: var(--orange);
  display: block;
  font-size: 30px;
  height: 26px;
  line-height: 26px;
  transition: var(--transition);
  transition-property: background, color;
  width: 26px;
}

.mapboxgl-popup-close-button:hover {
  background-color: var(--orange);
  color: var(--white);
}

/*--------------------------------------------------------------
# building
--------------------------------------------------------------*/
.building {
  width: 100%;
}

.building__holder {
  margin: 20px 0;
  border-radius: 16px;
  padding: 20px;
  background-color: var(--white);
}

.building__title {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  color: var(--black-v2);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.building__title span {
  display: block;
  font-weight: 100;
}

.building__floors-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.building__floor {
  cursor: pointer;
  margin-bottom: 40px;
  margin-right: 4%;
  text-align: center;
  transition: var(--transition);
  transition-property: margin, width;
  width: 22%;
}

.building__location {
  color: var(--black-v2);
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.28px;
  text-decoration-line: underline;
  position: relative;
  padding-left: 24px;
  transition: var(--transition);
  &:hover {
    color: var(--orange-v3);
  }
}

.building__location::before {
  content: "";
  background-image: url(./images/ico-location-dot-orange.svg);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
}

.building__floor-title {
  background-color: var(--orange-v3);
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding: 5px 10px 5px;
  border-radius: 8px 8px 0 0;
}

.building__floor-content {
  background-color: var(--white);
  border: 2px solid var(--grey-light);
  border-top: 0;
  height: 0;
  padding-bottom: 89%;
  position: relative;
  border-radius: 0 0 8px 80px;
}

.building__floor-content svg {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
}
@media only screen and (min-width: 993px) {
  .building__floor:nth-child(4n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .building__floor {
    margin-bottom: 20px;
    margin-right: 2.5%;
    /* margin: 0 2.5% 20px 0; */
    width: 30%;
  }
  .building__floor-title {
    font-size: 16px;
    font-weight: 600;
  }
  .building__floor:nth-child(3n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .building__floor {
    margin: 0 5% 20px 0;
    width: 47.5%;
  }
  .building__floor:nth-child(4n) {
    margin-right: 5%;
  }
  .building__floor:nth-child(2n) {
    margin-right: 0;
  }
  .building__floor-title {
    font-size: 14px;
    font-weight: 500;
  }
}

/*--------------------------------------------------------------
# floor
--------------------------------------------------------------*/
.floor {
  width: 100%;
}

.floor__link {
  color: var(--black-v2);
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.28px;
  text-decoration-line: underline;
  position: relative;
  transition: var(--transition);
  &:hover {
    color: var(--orange-v3);
  }
}

.floor__title {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 20px;
  width: 100%;
  gap: 16px;
  color: var(--black-v2);
}

.floor__title-left {
  font-size: 18px;
  font-style: normal;
  font-weight: 100;
  line-height: 110%;
}

.floor__title-right {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.28px;
  text-decoration-line: underline;
  position: relative;
  padding-left: 24px;
}

.floor__title-right::before {
  content: "";
  background-image: url(./images/ico-location-dot-orange.svg);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
}

.floor__plan {
  background-color: var(--white);
  border-radius: 16px;
  margin-bottom: 20px;
  width: 100%;
}

.floor__plan svg {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .floor__title {
    flex-direction: column-reverse;
    padding: 24px;
  }
  .floor__title-left {
    order: 2;
  }
}

@media only screen and (min-width: 768) and (max-width: 992px) {
  .floor__title-right {
    font-size: 22px;
  }
  .floor__title-left {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .floor__title {
    padding: 16px;
  }
  .floor__title-right {
    font-size: 18px;
    margin-bottom: 3px;
  }
  .floor__title-left {
    font-size: 18px;
  }
}

/*--------------------------------------------------------------
# token info
--------------------------------------------------------------*/
.token {
  display: flex;
  width: 100%;
  gap: 32px;
  padding: 40px 32px;
  margin: 20px 0;
  background-color: var(--white);
  border-radius: 16px;
}

.token__col {
  width: 50%;
}

.token__col-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 100;
  line-height: 110%;
  color: var(--black-v2);
}

.token__col-title--info {
  margin-bottom: 16px;
}

.token__info-text {
  display: flex;
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.token__info-text span:not(:last-child) {
  font-weight: 700;
  margin-right: 8px;
}

.token__info-text:last-child {
  margin-bottom: 0;
}

.token__info-text--small {
  font-size: 14px;
  margin: 24px 0;
}

@media only screen and (max-width: 768px) {
  .token {
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    margin-top: 20px;
    .btn {
      margin: 0 auto;
    }
  }
  .token__col {
    width: 100%;
  }
  .token__col-title--info {
    margin-bottom: 20px;
  }
}

/*--------------------------------------------------------------
# Card
--------------------------------------------------------------*/

.card {
  background-color: var(--black-v2);
  color: var(--white);
  max-width: 298px;
  padding: 24px;
  border-radius: 16px;
  font-family: PP Mori;
}

.card__content-name {
  font-size: 24px;
  font-style: normal;
  font-weight: 100;
  line-height: 110%;
  margin-bottom: 16px;
}

.card__location {
  padding-top: 24px;
  margin-bottom: 16px;
}

.card__location-icon {
  margin-right: 8px;
}

.card__location-link {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.28px;
  text-decoration-line: underline;
}

/*--------------------------------------------------------------
# Accordion
--------------------------------------------------------------*/

.accordion__container {
  margin-top: 20px;
}

.accordion {
  margin-bottom: 8px;
}

.accordion__name {
  position: relative;
  width: 100%;
  border-radius: 16px;
  padding: 0 16px;
  background-color: var(--green-v3);
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.accordion__name-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  transition: var(--transition);
}

.accordion__name--open .accordion__name-icon {
  transform: translateY(-50%) scaleY(-1);
}

.accordion__content {
  padding: 0 16px;
  overflow: hidden;
  max-height: 0;
  transition: var(--transition);
}

.accordion__item {
  padding: 24px 0 16px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
}

.accordion__item .card {
  max-width: 32.24%;
  width: 100%;
}

@media only screen and (max-width: 1100px) {
  .accordion__item .card {
    max-width: 48.5%;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .accordion__item .card {
    max-width: 100%;
    width: 100%;
  }
}
